import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import HeaderContainer from '../containers/headerContainer';
import FooterContainer from '../containers/footerContainer';

const Layout = ({ children, fixed, defaultColor }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <HeaderContainer
        defaultColor={defaultColor}
        siteTitle={site.siteMetadata.title}
        fixed={fixed}
      />
      <main>{children}</main>
      <FooterContainer siteTitle={site.siteMetadata.title} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
