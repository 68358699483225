import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
export const AboutPageInfo = styled.section`
  background: ${({ theme }) => theme.colors.lightBg};
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightBg};
  width: 70%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 2rem auto;
  padding: 2rem 5rem;
  @media ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: ${({ theme }) => theme.typography.captionText.size};
  font-weight: ${({ theme }) => theme.typography.captionText.weight};
  background: ${({ theme }) => theme.colors.primaryThree}1A;
  width: fit-content;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;
export const Description = styled.h5`
  color: ${({ theme }) => theme.colors.darkBg};
  font-size: ${({ theme }) => theme.typography.h5.size};
  margin-bottom: 2rem;
  line-height: ${({ theme }) => theme.typography.h5.line};
`;

export const IconsWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
`;
export const Links = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 0.4rem;
  font-size: ${({ theme }) => theme.typography.sText.size};
  padding: 1rem 1.3rem;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? 'rgba(0, 131, 143, 0.15) ' : theme.colors.lightBg};

  border-radius: 2rem;
  & > svg {
    margin-right: 0.5rem;
  }
`;
export const OwnerContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 3rem auto;
  padding: 2rem 5rem;
  @media ${(props) => props.theme.breakpoints.xs} {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;
export const Owner = styled.p`
  font-size: ${({ theme }) => theme.typography.h4.size};
  font-weight: ${({ theme }) => theme.typography.h4.weight};
`;

export const Signature = styled(Img)`
  max-width: 79px;
  margin: 2rem 0;
`;
