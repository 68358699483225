import React from 'react';

import {
  Container,
  Wrapper,
  Row,
  Column,
  Links,
  Title,
  Description,
  Logo,
  SocialLinks,
  SocialIconsContainer,
  Divider,
  Copyrights,
  LogoLink,
} from './styles/footer';

export default function Footer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Footer.Wrapper = function FooterWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};

Footer.Row = function FooterRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>;
};

Footer.Column = function FooterColumn({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>;
};

Footer.Links = function FooterLink({ children, ...restProps }) {
  return <Links {...restProps}>{children}</Links>;
};

Footer.Title = function FooterTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Footer.Description = function FooterDescription({ children, ...restProps }) {
  return <Description {...restProps}>{children}</Description>;
};

Footer.Logo = function FooterLogo({ children, ...restProps }) {
  return <Logo {...restProps}>{children}</Logo>;
};
Footer.SocialLinks = function FooterSocialLinks({ children, ...restProps }) {
  return <SocialLinks {...restProps}>{children}</SocialLinks>;
};
Footer.SocialIconsContainer = function FooterSocialIconsContainer({
  children,
  ...restProps
}) {
  return <SocialIconsContainer {...restProps}>{children}</SocialIconsContainer>;
};

Footer.Divider = function FooterDivider({ children, ...restProps }) {
  return <Divider {...restProps}>{children}</Divider>;
};
Footer.Copyrights = function FooterCopyrights({ children, ...restProps }) {
  return <Copyrights {...restProps}>{children}</Copyrights>;
};

Footer.LogoLink = function FooterLogoLink({ children, ...restProps }) {
  return <LogoLink {...restProps}>{children}</LogoLink>;
};
