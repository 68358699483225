import React from 'react';
import {
  AboutPageInfo,
  Container,
  Title,
  Description,
  Wrapper,
  IconsWrapper,
  Links,
  Owner,
  Signature,
  OwnerContainer,
} from './styles/aboutInformation';

const AboutPageInformation = ({ children, ...restProps }) => {
  return <AboutPageInfo {...restProps}>{children}</AboutPageInfo>;
};

AboutPageInformation.Title = function AboutPageTitle({
  children,
  ...restProps
}) {
  return <Title {...restProps}>{children}</Title>;
};
AboutPageInformation.Container = function AboutPageContainer({
  children,
  ...restProps
}) {
  return <Container {...restProps}>{children}</Container>;
};
AboutPageInformation.Description = function AboutPageDescription({
  children,
  ...restProps
}) {
  return <Description {...restProps}>{children}</Description>;
};
AboutPageInformation.Wrapper = function AboutPageWrapper({
  children,
  ...restProps
}) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};

AboutPageInformation.IconsWrapper = function AboutPageIcons({
  children,
  restProps,
}) {
  return <IconsWrapper {...restProps}>{children}</IconsWrapper>;
};
AboutPageInformation.Links = function AboutPageLinks({
  children,
  ...restProps
}) {
  return <Links {...restProps}>{children}</Links>;
};
AboutPageInformation.OwnerContainer = function AboutPageInformationOwnerContainer({
  children,
  ...restProps
}) {
  return <OwnerContainer {...restProps}>{children}</OwnerContainer>;
};

AboutPageInformation.Owner = function AboutPageInformationOwner({
  children,
  ...restProps
}) {
  return <Owner {...restProps}>{children}</Owner>;
};
AboutPageInformation.Signature = function AboutPageInformationSignature({
  children,
  ...restProps
}) {
  return <Signature {...restProps}>{children}</Signature>;
};
export default AboutPageInformation;
