import React from 'react';

import {
  SectionWrap,
  Container,
  Caption,
  Title,
  Content,
  Grid,
  Pane,
  Text,
  Group,
  Stats,
  StatsBody,
  StatsHead,
  Button,
  Picture,
  PictureWithFrame,
  ListNumber,
  ListText,
} from './styles/section';

function Section({ children, ...restProps }) {
  return <SectionWrap {...restProps}>{children}</SectionWrap>;
}

Section.Container = function SectionContainer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
};

Section.Caption = function SectionCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Section.Title = function SectionTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Section.Content = function SectionContent({ children, ...restProps }) {
  return <Content {...restProps}>{children}</Content>;
};

Section.Grid = function SectionGrid({ children, ...restProps }) {
  return <Grid {...restProps}>{children}</Grid>;
};

Section.Pane = function SectionPane({ children, ...restProps }) {
  return <Pane {...restProps}>{children}</Pane>;
};

Section.Text = function SectionText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Section.Group = function SectionGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};

Section.Stats = function SectionStats({ children, ...restProps }) {
  return <Stats {...restProps}>{children}</Stats>;
};

Section.StatsBody = function SectionStatsBody({ children, ...restProps }) {
  return <StatsBody {...restProps}>{children}</StatsBody>;
};

Section.StatsHead = function SectionStatsHead({ children, ...restProps }) {
  return <StatsHead {...restProps}>{children}</StatsHead>;
};

Section.Button = function SectionButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Section.Picture = function SectionPicture({ ...restProps }) {
  return <Picture {...restProps} />;
};

Section.ListNumber = function SectionListNumber({ children, ...restProps }) {
  return <ListNumber {...restProps}>{children}</ListNumber>;
};

Section.ListText = function SectionListText({ children, ...restProps }) {
  return <ListText {...restProps}>{children}</ListText>;
};

Section.PictureWithFrame = function SectionPictureWithFrame({ ...restProps }) {
  return <PictureWithFrame {...restProps} />;
};

export default Section;
