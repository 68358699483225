import React from 'react';
import {
  Button,
  ButtonContainer,
  Container,
  HeroSection,
  LeftSection,
  LeftSectionHeading,
  LeftSectionSubHeading,
  LeftSectionWelcome,
  RightSection,
  Video,
  PlayButton,
  VideoDisplay,
  VideoClose,
  Player,
} from './styles/hero';

const Hero = ({ children, ...restProps }) => {
  return <HeroSection {...restProps}>{children}</HeroSection>;
};

Hero.Container = function HeroContainer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
};

Hero.LeftSection = function HeroLeftSection({ children, ...restProps }) {
  return <LeftSection {...restProps}>{children}</LeftSection>;
};

Hero.LeftSectionWelcome = function HeroWelcome({ children, ...restProps }) {
  return <LeftSectionWelcome {...restProps}>{children}</LeftSectionWelcome>;
};

Hero.LeftSectionHeading = function HeroHeading({ children, ...restProps }) {
  return <LeftSectionHeading {...restProps}>{children}</LeftSectionHeading>;
};

Hero.LeftSectionSubHeading = function HeroSubHeading({
  children,
  ...restProps
}) {
  return (
    <LeftSectionSubHeading {...restProps}>{children}</LeftSectionSubHeading>
  );
};

Hero.ButtonContainer = function HeroButtonContainer({
  children,
  ...restProps
}) {
  return <ButtonContainer {...restProps}>{children}</ButtonContainer>;
};

Hero.Button = function HeroButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Hero.RightSection = function HeroRightSection({ children, ...restProps }) {
  return <RightSection {...restProps}>{children}</RightSection>;
};

Hero.Video = function HeroVideo({ children, ...restProps }) {
  return <Video {...restProps}>{children}</Video>;
};

Hero.PlayButton = function HeroPlayButton({ children, ...restProps }) {
  return <PlayButton {...restProps}>{children}</PlayButton>;
};

Hero.VideoDisplay = function HeroVideoDisplay({ children, ...restProps }) {
  return <VideoDisplay {...restProps}>{children}</VideoDisplay>;
};

Hero.Player = function HeroPlayer({ children, ...restProps }) {
  return <Player {...restProps}>{children}</Player>;
};

Hero.VideoClose = function HeroVideoClose({ children, ...restProps }) {
  return <VideoClose {...restProps}>{children}</VideoClose>;
};

export default Hero;
