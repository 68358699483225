import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const HeaderSection = styled.section`
  position: relative;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `}

  width: 100%;
  height: 9rem;
  z-index: 1000;

  ${({ color }) =>
    color &&
    css`
      background: ${color};
      height: 7rem;
      box-shadow: ${({ theme }) => theme.shadows.shadowThree};
    `}

  ${({ bg }) =>
    bg &&
    css`
      background: ${bg};
      height: 6rem;
      box-shadow: ${({ theme }) => theme.shadows.shadowThree};
    `};
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 1.2rem;
  width: 100%;
  height: inherit;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Pane = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 80px;
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.typography.btnText.size};
  font-weight: ${({ theme }) => theme.typography.btnText.weight};
  text-transform: uppercase;

  background: ${({ theme }) => theme.colors.accentOne};
  color: ${({ theme }) => theme.colors.darkBg};

  border-radius: 0.5rem;
  border: 0.5rem solid ${({ theme }) => theme.colors.accentOne};
  padding: 0.5rem 0.7rem;

  transition: background 250ms;

  &:hover,
  &:focus {
    background: transparent;
    color: ${({ theme }) => theme.colors.accentOne};
    font-weight: 900;
  }

  ${({ bg }) =>
    bg &&
    css`
      background: ${({ theme }) => theme.colors.primaryTwo};
      border: 0.5rem solid ${({ theme }) => theme.colors.primaryTwo};
      color: ${({ theme }) => theme.colors.lightText};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.darkBg};
      }
    `}
`;

export const Menu = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.lightBg};
  width: 3.2rem;
  height: 0.2rem;
  transform: translateY(-0.7rem);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    width: 3.2rem;
    height: 0.2rem;

    transform: translateY(0.7rem);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    width: 3.2rem;
    height: 0.2rem;

    transform: translateY(1.4rem);
  }

  ${({ bg }) =>
    bg &&
    css`
      background: ${({ theme }) => theme.colors.darkBg};
    `};

  ${({ color }) =>
    color &&
    css`
      background: ${({ theme }) => theme.colors.darkBg};
    `};
`;

export const MenuWrapper = styled.div`
  display: none;
  padding: 1rem 0;
  margin-right: 3rem;
  cursor: pointer;

  &:hover ${Menu}::before {
    width: 2.5rem;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    display: flex;
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-right: 1rem;
  }
`;
