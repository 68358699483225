import React from 'react';
import Footer from '../components/footer';
import { FiFacebook, FiTwitter, FiLinkedin, FiInstagram } from 'react-icons/fi';

import { graphql, useStaticQuery } from 'gatsby';

const FooterContainer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      footer: allContentfulFooter {
        edges {
          node {
            id
            title
            subtitle
            footerItems {
              slug
              title
            }
          }
        }
      }
      branding: file(relativePath: { eq: "text_logo.png" }) {
        childImageSharp {
          fixed(width: 160, height: 55, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Footer>
      <Footer.Wrapper>
        <Footer.Row>
          <Footer.LogoLink to="/">
            <Footer.Logo fixed={data.branding.childImageSharp.fixed} />
          </Footer.LogoLink>
        </Footer.Row>
        <Footer.Row>
          <Footer.Column diff>
            <Footer.Description>
              Our mission is to provide the highest quality pest services in the
              Midwest. To provide accurate information to our customers while
              helping to provide a pest-free environment. Our commitment to the
              planet and a greener environment is unmatched by any other
              company. All services we provide are safe and guaranteed.
            </Footer.Description>
            <Footer.SocialIconsContainer>
              <Footer.SocialLinks
                href="https://www.facebook.com/UnitechPestAndTermite"
                target="_blank"
              >
                <FiFacebook color="white" size="3rem" />
              </Footer.SocialLinks>
              <Footer.SocialLinks
                href="https://twitter.com/unitechpestcom/"
                target="_blank"
              >
                <FiTwitter color="white" size="3rem" />
              </Footer.SocialLinks>
              <Footer.SocialLinks
                href="https://www.linkedin.com/company/unitech-pest-and-termite/about"
                target="_blank"
              >
                <FiLinkedin color="white" size="3rem" />
              </Footer.SocialLinks>
              <Footer.SocialLinks
                href="https://www.instagram.com/UnitechPestAndTermite/"
                target="_blank"
              >
                <FiInstagram color="white" size="3rem" />
              </Footer.SocialLinks>
            </Footer.SocialIconsContainer>
          </Footer.Column>
          {data.footer.edges
            .map(({ node: { id, title, subtitle, footerItems } }) => {
              return (
                <Footer.Column key={title}>
                  <Footer.Title>{title}</Footer.Title>
                  {footerItems
                    ? footerItems.map((item) => {
                        return (
                          <Footer.Links
                            key={item.title}
                            to={item.slug ? item.slug : '/'}
                          >
                            {item.title}
                          </Footer.Links>
                        );
                      })
                    : null}
                </Footer.Column>
              );
            })
            .reverse()}
        </Footer.Row>
        <Footer.Divider />
        {/* Site title was not working for some reason */}
        <Footer.Copyrights>
          {' '}
          © {new Date().getFullYear()} All rights reserved | {siteTitle}
        </Footer.Copyrights>
      </Footer.Wrapper>
    </Footer>
  );
};

export default FooterContainer;
