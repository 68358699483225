import React from 'react';

import {
  Container,
  Header,
  Title,
  Link,
  Caption,
  Devider,
  Item,
  List,
  SubTitle,
} from './styles/dropdown';

function Dropdown({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Dropdown.Header = function DropdownHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>;
};

Dropdown.Title = function DropdownTitle({ to, children, ...restProps }) {
  return to ? (
    <Title {...restProps}>
      <Link to={to}>{children}</Link>
    </Title>
  ) : (
    <Title {...restProps}>{children}</Title>
  );
};

Dropdown.List = function DropdownList({ children, ...restProps }) {
  return <List {...restProps}>{children}</List>;
};

Dropdown.SubTitle = function DropdownSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Dropdown.Caption = function DropdownCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Dropdown.Devider = function DropdownDevider({ ...restProps }) {
  return <Devider {...restProps} />;
};

Dropdown.Item = function DropdownItem({ to, children, ...restProps }) {
  return to ? (
    <Link to={to}>
      <Item {...restProps}>{children}</Item>
    </Link>
  ) : (
    <Item {...restProps}>{children}</Item>
  );
};

export default Dropdown;
