import styled from 'styled-components/macro';
import Img from 'gatsby-image';
import { css } from 'styled-components';
import { Link } from 'gatsby';

export const SectionWrap = styled.section`
  background: ${({ theme }) => theme.colors.lightBg};

  padding: ${({ padding }) => (padding ? padding : 0)};
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 4.5rem 1.2rem;
  width: 100%;
  height: inherit;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Caption = styled.p`
  width: fit-content;
  background: ${({ theme }) => theme.colors.primaryThree}1A;
  padding: 0.8rem;
  border-radius: 0.4rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;

  font-size: ${({ theme }) => theme.typography.captionText.size};
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.typography.h3.size};
  max-width: 50rem;
  width: 100%;
  text-align: center;
  margin-bottom: 7rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 5.5rem;

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-template-columns: repeat(auto-fill, minmax(310px, 435px));
    grid-gap: 3rem;
  }
`;

export const Pane = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  width: 100%;

  & > * {
    flex-grow: 1;
  }

  ${({ left }) =>
    left &&
    css`
      padding-right: 5rem;

      @media ${({ theme }) => theme.breakpoints.lg} {
        padding-right: 2rem;
      }

      @media ${({ theme }) => theme.breakpoints.md} {
        padding-right: 0;
        margin-bottom: 3rem;
      }
    `}

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}  

  ${({ right }) =>
    right &&
    css`
      padding-left: 5rem;

      @media ${({ theme }) => theme.breakpoints.lg} {
        padding-left: 2rem;
      }

      @media ${({ theme }) => theme.breakpoints.md} {
        padding-left: 0;
        margin-bottom: 3rem;
      }
    `}
  
  ${({ width }) =>
    width &&
    css`
      flex: ${width}%;
    `}
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.typography.h5.size};
  line-height: ${({ theme }) => theme.typography.h5.line};
  margin-bottom: 3.5rem;
`;

export const ListNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.gradients.gradientOne};
  color: ${({ theme }) => theme.colors.lightText};
  font-size: 4.5rem;
  font-weight: 700;
`;

export const ListText = styled.p`
  width: 80%;
  margin-left: 1.5rem;
  font-size: ${({ theme }) => theme.typography.h4.size};
  font-weight: ${({ theme }) => theme.typography.h3.weight};
`;

export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'flex-start')};
  padding: ${({ padding }) => (padding ? padding : 0)};

  @media ${({ theme }) => theme.breakpoints.md} {
    justify-content: flex-start;
  }

  ${({ md }) =>
    md &&
    css`
      @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
      }

      & > ${Group}:first-of-type {
        margin-bottom: 4rem;
      }
    `}
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-right: 3rem;
  }
`;

export const StatsHead = styled.h4`
  font-size: ${({ theme }) => theme.typography.h4.size};
  font-weight: ${({ theme }) => theme.typography.h4.weight};
  margin-bottom: 0.4rem;
`;

export const StatsBody = styled.h5`
  font-size: ${({ theme }) => theme.typography.h5.size};
  font-weight: ${({ theme }) => theme.typography.h4.weight};
  color: ${({ theme }) => theme.colors.primaryThree};
`;

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: ${({ theme }) => theme.typography.btnText.size};
  font-weight: ${({ theme }) => theme.typography.btnText.weight};
  text-transform: uppercase;
  border: 0.1rem solid ${({ theme }) => theme.colors.darkBg};
  padding: 1rem 1.2rem;
  border-radius: 0.4rem;
  background: transparent;
  outline: none;
  transition: all 200ms;
  cursor: pointer;
  margin-top: 3.5rem;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2.1rem ${({ theme }) => theme.colors.darkBg};
    color: ${({ theme }) => theme.colors.lightText};
  }
`;

export const Picture = styled(Img)``;

export const PictureWithFrame = styled(Img)`
  border: 1.6rem solid ${({ theme }) => theme.colors.lightText};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.shadowOne};
`;
