import styled from 'styled-components/macro';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.lightBg};
  width: 35rem;
  height: 100vh;
  padding: 1rem 3rem;
  transition: transform 250ms ease;

  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(40rem)')};

  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 100vw;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.2rem;
  margin-bottom: 1.5rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyText};
`;

export const Close = styled.h4`
  color: ${({ theme }) => theme.colors.primaryThree};
  cursor: pointer;
`;
