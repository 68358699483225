import styled from 'styled-components/macro';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.lightText};
  width: 100%;
  height: 50rem;
  padding: 1.5rem 4.5rem;
  border-radius: 0.6rem;
  box-shadow: ${({ theme }) => theme.shadows.shadowOne};
  transition: transform 50ms;

  &:hover,
  &:focus {
    transform: scale(1.01);
    background: ${({ theme }) => theme.colors.darkBg};
    color: ${({ theme }) => theme.colors.lightBg};
    box-shadow: ${({ theme }) => theme.shadows.shadowTwo};
  }
`;

export const Image = styled(Img)`
  margin-bottom: 1rem;
`;

export const Title = styled.h4`
  text-align: center;
  margin-bottom: 4rem;
`;

export const Description = styled.p`
  text-align: center;
  flex: 1;
`;

export const Button = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.typography.btnText.weight};
  text-transform: uppercase;
  background: ${({ theme, white }) =>
    !white ? theme.colors.accentOne : theme.colors.lightBg};
  color: ${({ theme }) => theme.colors.darkBg};
  border-radius: 0.5rem;
  border: 0.5rem solid
    ${({ theme, white }) =>
      !white ? theme.colors.accentOne : theme.colors.lightBg};

  padding: 0.5rem 1.5rem;
  transition: background 250ms;
  cursor: pointer;
`;
