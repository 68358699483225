import styled from 'styled-components/macro';

export const AboutHeroContainer = styled.div`
  background: ${({ theme }) => theme.gradients.gradientOne};
  padding: ${({ padding }) => (padding ? padding : 0)};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.typography.h3.size};
  color: ${({ theme }) => theme.colors.lightText};
  margin: 0 auto;
  max-width: 46rem;
  text-align: center;
  padding-bottom: 2.5rem;

  @media ${(props) => props.theme.breakpoints.xl} {
    width: 80%;
  }
`;

export const Image = styled.svg`
  background-image: url(images/waves.svg);
`;
