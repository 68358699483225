import styled from 'styled-components/macro';
import { Link as GatsbyLink } from 'gatsby';

export const Container = styled.div`
  padding: 1.5rem 0;
`;

export const Header = styled.header``;

export const Title = styled.h4`
  font-weight: 400;
`;

export const Link = styled(GatsbyLink)``;

export const List = styled.ul`
  list-style: none;
  padding: 2.2rem 0.6rem 0;
  border-radius: 0.3rem;
  background: ${(props) => props.theme.colors.lightBg};
  transition: all 200ms ease;
`;

export const SubTitle = styled.li`
  font-size: ${({ theme }) => theme.typography.sText.size};
  color: ${({ theme }) => theme.colors.darkText};
  margin-bottom: 0.2rem;
`;

export const Caption = styled.p`
  font-size: ${({ theme }) => theme.typography.xsText.size};
  color: ${({ theme }) => theme.colors.greyText};
  margin-bottom: 0.2rem;
`;

export const Devider = styled.div`
  height: 0.1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.greyText}72;
  margin: 0.8rem 0;
`;

export const Item = styled.li`
  font-size: ${({ theme }) => theme.typography.sText.size};
  padding: 0.2rem 0;
  margin: 0.1rem 0;

  &:hover {
    background: ${({ theme }) => theme.colors.greyText}0C;
    border-radius: 0.4rem;
    padding-left: 0.5rem;
  }
`;
