import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Header, MobileMenu, Navigation } from '../components';
import DropdownContainer from './dropdownContainer';

const HeaderContainer = ({ siteTitle, defaultColor, color, fixed }) => {
  const data = useStaticQuery(graphql`
    query {
      nav: allContentfulNavigation {
        edges {
          node {
            id
            slug
            title
            subTitle
            caption
            megaMenu {
              id
              slug
              title
              icon {
                fixed {
                  src
                }
              }
            }
          }
        }
      }
      branding: file(relativePath: { eq: "text_logo.png" }) {
        childImageSharp {
          fixed(width: 160, height: 55, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(relativePath: { eq: "unitech.gif" }) {
        childImageSharp {
          fixed(width: 160, height: 100, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Header defaultColor={defaultColor} bg={color || `#F8F9FA`} fixed={fixed}>
      <Header.Container>
        <Header.Pane>
          <Header.Logo
            to="/"
            src={require('../images/unitech.gif')}
            alt={siteTitle}
          />
          <Navigation>
            {data.nav.edges.map(
              ({ node: { slug, id, title, subTitle, caption, megaMenu } }) => {
                return (
                  <Navigation.Title to={slug} key={id}>
                    {title}
                    {megaMenu ? (
                      <Navigation.List>
                        <Navigation.SubTitle>{subTitle}</Navigation.SubTitle>
                        <Navigation.Caption>{caption}</Navigation.Caption>
                        <Navigation.Devider />
                        {megaMenu.map((item) => {
                          return (
                            <Navigation.Item to={item.slug} key={item.id}>
                              {item.title}
                            </Navigation.Item>
                          );
                        })}
                      </Navigation.List>
                    ) : null}
                  </Navigation.Title>
                );
              }
            )}
          </Navigation>
        </Header.Pane>
        <Header.Pane>
          <Header.MenuWrapper onClick={() => setOpenMenu(!openMenu)}>
            <Header.Menu />
          </Header.MenuWrapper>
          <Header.Button href="tel:314-558-4847">
            (314) 558 - 4847
          </Header.Button>
        </Header.Pane>
        <MobileMenu open={openMenu}>
          <MobileMenu.Top>
            <MobileMenu.Close onClick={() => setOpenMenu(!openMenu)}>
              Close
            </MobileMenu.Close>
          </MobileMenu.Top>
          {data.nav.edges.map(
            (
              { node: { slug, id, title, subTitle, caption, megaMenu } },
              idx
            ) => (
              <DropdownContainer
                key={id}
                title={title}
                subTitle={subTitle}
                caption={caption}
                idx={idx}
                megaMenu={megaMenu}
                slug={slug}
              />
            )
          )}
        </MobileMenu>
      </Header.Container>
    </Header>
  );
};

export default HeaderContainer;
