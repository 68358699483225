import React from 'react';
import { AboutHeroContainer, Title, Image } from './styles/AboutHero';

const AboutHeroSection = ({ children, ...restProps }) => {
  return <AboutHeroContainer {...restProps}>{children}</AboutHeroContainer>;
};

AboutHeroSection.Title = function AboutHeroSectionTitle({
  children,
  ...restProps
}) {
  return <Title {...restProps}>{children}</Title>;
};

AboutHeroSection.Image = function AboutHeroSectionImage({
  children,
  ...restProps
}) {
  return <Image {...restProps}>{children}</Image>;
};
export default AboutHeroSection;
