import React from 'react';

import { Container, Top, Close } from './styles/mobile-menu';

function MobileMenu({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

MobileMenu.Top = function MobileMenuTop({ children, ...restProps }) {
  return <Top {...restProps}>{children}</Top>;
};

MobileMenu.Close = function MobileMenuClose({ children, ...restProps }) {
  return <Close {...restProps}>{children}</Close>;
};

export default MobileMenu;
