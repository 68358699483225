import styled from 'styled-components/macro';
import { Link } from 'gatsby';

export const HeroSection = styled.section`
  background: ${(props) => props.theme.gradients.gradientOne};
  padding: 17.5rem 0 5rem;

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 12rem 0.5rem 6rem;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 1.2rem;
  width: 100%;
  height: inherit;
  max-width: 1280px;
  display: flex;

  @media ${({ theme }) => theme.breakpoints.lg} {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.lg} {
    margin-bottom: 7rem;
    align-items: center;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-bottom: 6rem;
  }
`;

export const LeftSectionWelcome = styled.p`
  width: fit-content;
  background: ${({ theme }) => theme.colors.primaryThree}99;
  color: ${({ theme }) => theme.colors.lightText};
  font-size: ${({ theme }) => theme.typography.captionText.size};
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.8rem;
  margin-bottom: 3rem;
`;

export const LeftSectionSubHeading = styled.h2`
  font-weight: ${({ theme }) => theme.typography.h2.weight};
  font-size: ${({ theme }) => theme.typography.h2.size};
  line-height: ${({ theme }) => theme.typography.h2.line};
  color: ${({ theme }) => theme.colors.lightText};
  text-transform: uppercase;
`;

export const LeftSectionHeading = styled.h1`
  font-weight: ${({ theme }) => theme.typography.h1.weight};
  font-size: ${({ theme }) => theme.typography.h1.size};
  line-height: ${({ theme }) => theme.typography.h1.line};
  color: ${(props) => props.theme.colors.accentOne};
  text-transform: uppercase;
  margin: 1rem 0;

  @media ${({ theme }) => theme.breakpoints.sm} {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 3rem;

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 5rem;
    flex-direction: column;
    align-items: center;
  }
`;

export const Button = styled(Link)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.btnText.size};
  font-weight: ${({ theme }) => theme.typography.btnText.weight};
  text-transform: uppercase;
  background: ${({ theme, white }) =>
    !white ? theme.colors.accentOne : theme.colors.lightBg};
  color: ${({ theme }) => theme.colors.darkBg};
  border-radius: 0.5rem;
  border: 0.5rem solid
    ${({ theme, white }) =>
      !white ? theme.colors.accentOne : theme.colors.lightBg};

  padding: 0.5rem 0.6rem;
  transition: background 250ms;
  cursor: pointer;

  &:hover,
  &:focus {
    background: transparent;
    color: ${({ theme, white }) =>
      !white ? theme.colors.accentOne : theme.colors.lightText};
    font-weight: 900;
  }

  &:first-of-type {
    margin-right: 5rem;

    @media ${({ theme }) => theme.breakpoints.sm} {
      margin-right: 0rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const RightSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${({ theme }) => theme.breakpoints.lg} {
    justify-content: center;
  }
`;

export const Video = styled.div`
  position: relative;
  background: url(${(props) => props.snapshot}) no-repeat 60% 10% / auto;
  filter: grayscale(20%);
  border: 0.9rem solid ${({ theme }) => theme.colors.lightText};
  max-width: 58rem;
  width: 100%;
  height: 37.5rem;
  border-radius: 0.7rem;
  cursor: pointer;

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 62vw;
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    height: 64vw;
    background-size: cover;
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: none;
  outline: none;
  transform: translate(-50%, -50%);
  box-shadow: ${({ theme }) => theme.shadows.shadowTwo};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    background: ${({ theme }) => theme.colors.accentOne};
    filter: grayscale(0);
    clip-path: polygon(85% 50%, 0 0, 0 100%);
    transform: translate(-30%, -50%);
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.02);
  }
`;

export const VideoDisplay = styled.div`
  display: ${({ display }) => (display ? display : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow-x: hidden;
`;

export const Player = styled.video`
  position: absolute;
  top: 50%;
  left: 0;
  max-height: 75%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
`;

export const VideoClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.lightBg};
  font-size: 2.7rem;
  font-weight: 900;
  padding: 1.5rem 3rem;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.accentOne};
  }
`;
