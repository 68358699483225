import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { css } from 'styled-components';

export const Container = styled.nav`
  display: flex;
  margin-left: 9.5rem;

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin-left: 4.5rem;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    display: none;
  }
`;

export const List = styled.ul`
  list-style: none;
  position: fixed;
  padding: 1.2rem;
  border-radius: 0.3rem;
  background: ${(props) => props.theme.colors.lightBg};
  opacity: 0;
  pointer-events: none;
  transition: all 200ms ease;

  box-shadow: ${(props) => props.theme.shadows.shadowTwo};
`;

export const TitleLink = styled(Link)`
  color: ${(props) => props.theme.colors.lightText};

  ${({ bg }) =>
    bg &&
    css`
      color: ${(props) => props.theme.colors.darkText};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${(props) => props.theme.colors.darkText};
    `}
`;

export const Title = styled.div`
  position: relative;
  margin-right: 2rem;
  padding: 1.8rem 1rem;
  color: ${(props) => props.theme.colors.lightText};
  transition: opacity 200ms ease;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover ${List} {
    opacity: 1;
    pointer-events: all;
    transform: translateY(1.8rem);
  }

  ${({ bg }) =>
    bg &&
    css`
      color: ${(props) => props.theme.colors.darkText};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${(props) => props.theme.colors.darkText};
    `}
`;

export const SubTitle = styled.li`
  font-size: ${({ theme }) => theme.typography.sText.size};
  color: ${({ theme }) => theme.colors.darkText};
  margin-bottom: 0.2rem;
`;

export const Caption = styled.p`
  font-size: ${({ theme }) => theme.typography.xsText.size};
  color: ${({ theme }) => theme.colors.greyText};
  margin-bottom: 0.2rem;
`;

export const Devider = styled.div`
  height: 0.1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.greyText}72;
  margin: 0.8rem 0;
`;

export const Item = styled.li`
  font-size: ${({ theme }) => theme.typography.sText.size};
  padding: 0.2rem 0;
  margin: 0.1rem 0;

  &:hover {
    background: ${({ theme }) => theme.colors.greyText}0C;
    border-radius: 0.4rem;
    padding-left: 0.5rem;
  }
`;
