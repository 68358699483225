import React, { useContext } from 'react';
import { Link } from 'gatsby';

import {
  Container,
  Title,
  TitleLink,
  List,
  SubTitle,
  Caption,
  Devider,
  Item,
} from './styles/navigation';
import { HeaderContext } from '../../context/header';

export default function Navigation({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Navigation.Title = function NavigationTitle({ to, children, ...restProps }) {
  const { headerBg, defaultColor } = useContext(HeaderContext);

  return to ? (
    <Title {...restProps}>
      <TitleLink to={to} bg={headerBg} color={defaultColor}>
        {children}
      </TitleLink>
    </Title>
  ) : (
    <Title bg={headerBg} color={defaultColor} {...restProps}>
      {children}
    </Title>
  );
};

Navigation.List = function NavigationList({ children, ...restProps }) {
  return <List {...restProps}>{children}</List>;
};

Navigation.SubTitle = function NavigationSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Navigation.Caption = function NavigationCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Navigation.Devider = function NavigationDevider({ ...restProps }) {
  return <Devider {...restProps} />;
};

Navigation.Item = function NavigationItem({ to, children, ...restProps }) {
  return to ? (
    <Link to={to}>
      <Item {...restProps}>{children}</Item>
    </Link>
  ) : (
    <Item {...restProps}>{children}</Item>
  );
};
