import React, { useState } from 'react';
import { Dropdown } from '../components';

function DropdownContainer({ idx, slug, title, subTitle, caption, megaMenu }) {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <Dropdown>
      <Dropdown.Header
        tabIndex={idx}
        role="button"
        onKeyPress={() => setOpenDropdown(!openDropdown)}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <Dropdown.Title to={slug}>{title}</Dropdown.Title>
      </Dropdown.Header>
      {openDropdown && megaMenu ? (
        <Dropdown.List>
          <Dropdown.SubTitle>{subTitle}</Dropdown.SubTitle>
          <Dropdown.Caption>{caption}</Dropdown.Caption>
          <Dropdown.Devider />
          {megaMenu.map((item) => {
            return (
              <Dropdown.Item to={item.slug} key={item.id}>
                {item.title}
              </Dropdown.Item>
            );
          })}
        </Dropdown.List>
      ) : null}
    </Dropdown>
  );
}

export default DropdownContainer;
