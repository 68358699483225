import { Link } from 'gatsby';
import React, { useState, useEffect, useContext } from 'react';
import { HeaderContext } from '../../context/header';

import {
  HeaderSection,
  Logo,
  Container,
  Pane,
  Button,
  Menu,
  MenuWrapper,
} from './styles/header';

const Header = ({ defaultColor, fixed, bg, children, ...restProps }) => {
  const [headerBg, setHeaderBg] = useState(null);

  const handleHeaderBg = () => {
    if ((fixed && window.scrollY) >= 5) {
      setHeaderBg(bg);
    } else {
      setHeaderBg(null);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderBg);

    return () => {
      window.removeEventListener('scroll', handleHeaderBg);
    };
  }, [headerBg]);

  return (
    <HeaderSection
      color={defaultColor}
      fixed={fixed}
      bg={headerBg}
      {...restProps}
    >
      <HeaderContext.Provider value={{ headerBg, defaultColor }}>
        {children}
      </HeaderContext.Provider>
    </HeaderSection>
  );
};

Header.Container = function HeaderContainer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
};

Header.Pane = function HeaderPane({ children, ...restProps }) {
  return <Pane {...restProps}>{children}</Pane>;
};

Header.Logo = function HeaderLogo({ to, fixed, alt, children, ...restProps }) {
  const { headerBg, defaultColor } = useContext(HeaderContext);

  return (
    <Link to={to}>
      <Logo
        fixed={fixed}
        alt={alt}
        {...restProps}
        bg={headerBg}
        color={defaultColor}
      />
    </Link>
  );
};

Header.Button = function HeaderButton({ href, children, ...restProps }) {
  const { headerBg } = useContext(HeaderContext);

  return (
    <Button bg={headerBg} href={href} {...restProps}>
      {children}
    </Button>
  );
};

Header.MenuWrapper = function HeaderMenuWrapper({ children, ...restProps }) {
  return <MenuWrapper {...restProps}>{children}</MenuWrapper>;
};

Header.Menu = function HeaderMenu({ children, ...restProps }) {
  const { headerBg, defaultColor } = useContext(HeaderContext);

  return (
    <Menu {...restProps} color={defaultColor} bg={headerBg}>
      {children}
    </Menu>
  );
};

export default Header;
