import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { css } from 'styled-components';

import Img from 'gatsby-image';

export const Container = styled.div`
  padding: 40px 0;

  background: ${(props) => props.theme.colors.darkBg};
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: ${({ diff }) => (diff ? '0' : '0 60px')};

  grid-column: ${({ diff }) => (diff ? '1 / span 2' : 0)};

  justify-content: ${({ diff }) => (diff ? 'space-around' : 'flex-start')};

  @media (min-width: 1029px) {
    padding: 0;
  }
  @media ${(props) => props.theme.breakpoints.xl} {
    padding: 0;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    width: ${({ diff }) => (diff ? '100%' : '45%')};
    margin: 0 auto;
    text-align: center;
  }
  @media ${(props) => props.theme.breakpoints.xs} {
    width: ${({ diff }) => (diff ? '100%' : '100%')};
    margin: 0 auto;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  @media ${(props) => props.theme.breakpoints.md} {
    display: flex;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: initial;
  }
`;

export const Links = styled(Link)`
  color: ${({ theme }) => theme.colors.lightBg};
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.typography.normalText.size};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.accentOne};
    transition: 200ms ease-in;
  }
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.typography.h5.size};
  color: ${(props) => props.theme.colors.accentOne};
  margin-bottom: 40px;
  font-weight: ${({ theme }) => theme.typography.h5.weight};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.lightBg};
  font-size: ${({ theme }) => theme.typography.normalText.size};
  font-weight: ${({ theme }) => theme.typography.normalText.weight};
`;

export const LogoLink = styled(Link)``;
export const Logo = styled(Img)`
  margin: 30px 0;
  ${({ bg }) =>
    !bg &&
    css`
      filter: brightness(1) invert(100%);
    `}
`;

export const SocialLinks = styled.a``;
export const SocialIconsContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  @media ${(props) => props.theme.breakpoints.xl} {
    margin: 20px 0;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    margin: 20px auto;
    text-align: center;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightBg};
`;

export const Copyrights = styled.p`
  color: ${({ theme }) => theme.colors.lightBg};
  text-align: center;
  margin: 10px 0;
`;
